












import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import gql from 'graphql-tag';
import ContactElem from '@/components/reusable/ContactElem.vue';

interface Employ {
  id: number;
  firstName: string;
  lastName: string;
  funktion: string;
  eMail: string;
  telefonnummer: string;
  photo: {
    id: number;
    url: string;
  }
  simpleDescription: string;
  imgExpansionStatus?: number;
  img?: string;
}

@Component({
  components: { ContactElem },
})
export default class ContactList extends Vue {
  private placeholderImg: string = 'https://placeimg.com/640/480/people';

  public imgCollapsed: number = 200;

  public imgExpanded: number = 500;

  public employees: Employ[] = [];

  created() {
    this.$apollo.query<{entry:{userList: Employ[]}}>({
      query: gql`query {
        entry(section: "about") {
          ... on About_About_Entry {
            userList {
              ... on User {
                id
                firstName
                lastName
                eMail
                telefonnummer
                funktion
                simpleDescription
                photo {
                  ... on profil_Asset {
                    id
                    url @transform (height: 1000)
                  }
                }
              }
            }
          }
        }
      }
      `,
    }).then((res) => {
      if (res.data.entry.userList != null) {
        const result = res.data.entry.userList.map((employ) => ({
          ...{
            img: this.placeholderImg,
            imgExpansionStatus: this.imgCollapsed,
          },
          ...employ,
        }));
        this.employees = [...result];
      }
    });
  }
}
