







import Vue from 'vue';
import Component from 'vue-class-component';
import GenericContent from '@/components/content/GenericContent.vue';
import ContactList from '@/components/content/ContactList.vue';

@Component({
  components: {
    ContactList,
    GenericContent,
  },
})
export default class About extends Vue {

}
